html{
  margin:0;
  padding:0;
  max-height:100vh;
  overflow:hidden;
}

body {
  height: 100%;
}

.App {
  text-align: center;
  height: 200px;
  width: 200px;
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.projects-container {
  color: white;
}

.openingPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12vh;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  animation: fadeOutAnimation linear 1s forwards;
  animation-delay: 3s;
}

.openingPage > div {
  margin: 5vh 0;
}

.opening-first-text {
    opacity: 0;
    animation: fadeInAnimation linear 2s forwards;
}

.opening-second-text {
  opacity: 0;
  animation: fadeInAnimation linear 2s forwards;
  animation-delay: 1s;
}

.opening-third-text {
  opacity: 0;
  animation: fadeInAnimation linear 2s forwards;
  animation-delay: 2s;
}

#tsparticles {
  height: 100%;
  animation: changeBackGround linear 1s forwards;
  animation-delay: 4s;
}

.mainPage-container {
  display: flex;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
}

.mainPage-leftside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dotsContainer {
  display: flex;
  color: white;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 5vw;
}
.dot-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1vh 0;

}

.dot-sub:hover {
  cursor: pointer;
}

.red-container {
  display: flex;
  flex-direction: column;
}

.fa {
  font-size: 60;
  color: gray;
}

.fa:hover {
  opacity: 1;
  color: white;
}

.red-container > a {
  font-size: 60;
  margin:  1vh 0;
}



.mainPage-sections {
  color: white;
  width: 90vw;
}

.summary-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-duration: 1s;
  animation-name: fadeAndScale;
  animation-timing-function: cubic-bezier(.71,.55,.62,1.57);
}

.summary-container-deactivated {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 9vh;
  font-weight: bold;
  align-self: baseline;
  margin: 5vh 5vw;
  width: 90%;
  text-align: left;

}
.summary-header > img {
  width: 28vh;
  height: 30vh;
  border-radius: 50%;
}

.summary-text-container {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 4.6vh;
  line-height: 5.7vh;
  margin: 6vh 10vw;
  text-align: left;
  text-align: left;
}

.summary-text {
  margin-bottom: 15vh;
}

.summary-text-container > p {
  font-size: 3.5vh;
  margin: 0;
}

.summary-text-container > i {
  font-size: 2.5vh;
  font-weight: lighter;
}

@keyframes fadeAndScale {
  from {
      opacity: 0;
      transform: scale(.9, .9);
  }
  to {
      opacity: 1;
      transform: scale(1, 1);
  }
}

@keyframes changeBackGround {
  0% { 
    background-color: linear-gradient(135deg, #000000 0%, #1F013B 88%, #3B1175 100%); 
} 
100% {
    background-color: rgb(9, 0, 14);
}
}

@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% {
      opacity: 1;
  }
} 

@keyframes fadeOutAnimation {
  0% { 
    opacity: 1; 
  } 
  100% {
      opacity: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* SKILLS CSS */

.skills-container > h1 {
  font-size: 7vh;
}

.skills-techcard-container {
  height: 75vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.techCard-container {
  width: 12vw;
  height: 25vh;
  margin: 0vh 2vw;
  border-radius: 10%;
  background-color: rgba(255, 255, 255, 0.663);
  color: black;
}

.techCard-container > img {
  width: 6vw;
  height: 10vh;
}

/* PROJECTS CSS */

.projects-container > h1 {
  font-size: 7vh;
}

.projectcards-container {
  display: flex;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-weight: bold;
  font-size: 2vw;
  margin: 2vw;
  width: 30vw;
  height: 60vh;
  background-color: rgba(255, 255, 255, 0.663);
  border-radius: 10%;
}

.project-card:hover {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.project-card > img {
  margin: 5vh 0;
  height: 30vh;
  width: 20vw;
}

.projectdetail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: #000000b6;
  color: black;
  height: 100vh;
  width: 100vw;
}

.projectdetail-container > div {
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  height: 80vh;
  width: 60vw;
  
}


.projectdetailImg-container {
  display: flex;
  justify-content: space-between;
  height: 30vh;
  width: 50vw;
  align-self: center;
}

.projectdetail {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.projectdetail > span {
  height: 3vh;
  width: 95%;
  text-align: end;
  font-size: 3vh;
}

.projectdetail > span:hover {
  color: gray;
  cursor: pointer;
}

.projectdetail > h2 {
  font-size: 5vh;
  margin-top: 0;
}

.projectdetail > p {
  font-size: 2.5vh;
  margin: 2vh 5vw;
  text-align: left;
}

.projectdetail > a {
  font-size: 3vh;
  margin-top: 3vh;
  text-align: right;
  text-decoration: none;
}

.projectdetailImg-container > img {
  width: 22vw;
  height: 28vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dotSelected {
  background-color: 'white';
  border-radius: 50;
  width: 15;
  height: 15;
  margin: 9;
}

.dotNotSelected {
  background-color: 'gray';
  border-radius: 50;
  width: 10;
  height: 10;
  margin: 9;
}

/* RESPONSIVE CSS */

@media screen and (max-width: 415px){

  #tsparticles {
    height: 90%;
  }

  .mainPage-container {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .mainPage-leftside {
    flex-direction: column-reverse;
    justify-content: center;
  }
  
  .red-container {
    flex-direction: row;
    justify-content: center;
  }

  .red-container > a {
    margin: 3vw;
  }
  

  .dotsContainer {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .dot-sub {
    margin: 2vw;
  }

  .dot-sub > div:nth-child(2) {
    width: 50px;
    height: 50px;
  }


  .mainPage-sections {
    display: flex;
    width: 100%;
    height:80%;
    flex-direction: column;
    justify-content: center;
  }

  .summary-header {
    font-size: 6vw;
    align-self: center;
    width: 80%;
    margin-top: 4vh;
    margin-bottom: 1vh;
    line-height: 4vh;
  }

  .summary-header > img {
    width: 50%;

  }

  .summary-text {
    margin: 0;
    font-size: 2.2vh;
    line-height: normal;
    text-align: justify;
  }

  .summary-text-container > p {
    margin-top: 5vh;
    font-size: 2vh;
    line-height: normal;
    text-align: justify;
  }

  .summary-text-container > i {
    margin-top: 5vh;
    font-size: 1.5vh;
    line-height: normal;
    text-align: justify;
  }

  .skills-container {
    height: 84vh;
  }

  .skills-container > h1 {
    font-size: 8vw;
  }
  
  .skills-techcard-container {
    height: 52vh;
  }

  .techCard-container {
    height: 12vh;
    width: 20vw;
  }

  .techCard-container > h3 {
    font-size: 3vw;
  }

  .techCard-container > img {
    height: 4vh;
    width: 4vh;
  }

  .projects-container {
    height: 80vh;
  }

  .projects-container > h1 {
    font-size: 8vw;
  }

  .projectcards-container {
    height: 68vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-card {
    height: 20vh;
    width: 20vh;
    font-size: 1.8vh;
  }

  .project-card > img {
    height: 12vh;
    width: 20vh;
    margin: 0 0 1vh 0;
  }

  .projectdetail-container {
    width: 100%;
    height: 100%;
  }

  .projectdetail-container > div {
    width: 85%;
    height: 60%;
  }

  .projectdetail > h2 {
    font-size: 3vh;
  }

  .projectdetail > p {
    font-size: 1.5vh;
  }

  .projectdetail > a {
    font-size: 2vh;
    margin: 1vh 0;
  }

  .projectdetailImg-container {
    width: 100%;
    justify-content: center;
  }

  .projectdetailImg-container > img:nth-child(1) {
    width: 0;
    height: 0;
  }

  .projectdetailImg-container > img:nth-child(2) {
    margin: 0;
    width: 90%;
    height: 90%;
  }

}

@media (min-width: 460px) and (max-width: 670px) {
 
  #tsparticles {
    height: 90%;
  }

  .mainPage-container {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .mainPage-leftside {
    flex-direction: column-reverse;
    justify-content: center;
  }
  
  .red-container {
    flex-direction: row;
    justify-content: center;
  }

  .red-container > a {
    margin: 3vw;
  }
  

  .dotsContainer {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .dot-sub {
    margin: 2vw;
  }

  .dot-sub > div:nth-child(2) {
    width: 50px;
    height: 50px;
  }


  .mainPage-sections {
    display: flex;
    width: 100%;
    height:80%;
    flex-direction: column;
    justify-content: center;
  }

  .summary-header {
    font-size: 6vw;
    align-self: center;
    width: 80%;
    height: 30%;
    margin-top: 2vh;
    margin-bottom: 1vh;
  }

  .summary-header > img {
    width: 40%;
    height: 70%;
  }

  .summary-text {
    margin: 0;
    font-size: 2.5vh;
    line-height: normal;
    text-align: justify;
  }

  .summary-text-container > p {
    margin-top: 5vh;
    font-size: 2.5vh;
    line-height: normal;
    text-align: justify;
  }

  .summary-text-container > i {
    margin-top: 5vh;
    font-size: 1.5vh;
    line-height: normal;
    text-align: justify;
  }

  .skills-container {
    height: 84vh;
  }

  .skills-container > h1 {
    font-size: 8vw;
  }
  
  .skills-techcard-container {
    height: 52vh;
  }

  .techCard-container {
    height: 15vh;
    width: 20vw;
  }

  .techCard-container > h3 {
    font-size: 3vw;
  }

  .techCard-container > img {
    height: 5vh;
    width: 5vh;
  }

  .projects-container {
    height: 80vh;
  }

  .projects-container > h1 {
    font-size: 8vw;
  }

  .projectcards-container {
    height: 65vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-card {
    height: 20vh;
    width: 24vh;
    font-size: 2vh;
  }

  .project-card > img {
    height: 12vh;
    width: 24vh;
    margin: 0 0 1vh 0;
  }

  .projectdetail-container {
    width: 100%;
    height: 100%;
  }

  .projectdetail-container > div {
    width: 85%;
    height: 60%;
  }

  .projectdetail > h2 {
    font-size: 3vh;
  }

  .projectdetail > span {
    font-size: 2vh;
    width: 90%;
  }

  .projectdetail > span:hover {
    cursor: pointer;
  }

  .projectdetail > p {
    font-size: 2vh;
  }

  .projectdetail > a {
    font-size: 2vh;
    margin: 1vh 0;
  }

  .projectdetailImg-container {
    width: 100%;
    justify-content: center;
  }

  .projectdetailImg-container > img:nth-child(1) {
    width: 0;
    height: 0;
  }

  .projectdetailImg-container > img:nth-child(2) {
    margin: 0;
    width: 90%;
    height: 90%;
  }

}